import React from "react";

function Home() {
  return (
    <div className="home">
      <h1 className="hero_title">BATTLE FOR YOUR BRAIN</h1>
      <h2 className="hero_subtitle">OUT ON 17th JANUARY</h2>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/TtPosquIHEM?si=t1XtIljzVol-i7CO"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <div className="home_info_container">
        <h3>
          BARCELONA'S MANDUBA BRIDGES UK DUB AND PUNK SPIRIT WITH NEW RELEASE
        </h3>
        <p>
          Drawing from their extensive background in reggae and dub projects,
          three veteran musicians have joined forces to form Manduba, a live dub
          project that merges UK steppas, electronic soundscapes, and raw punk
          energy.
        </p>
        <p>
          Their first track, "Battle for Your Brain," embodies the intersection
          of steppas dub intensity and punk rock attitude. Built on a foundation
          of heavyweight bass, the track features distortion-laden guitar work
          and impactful vocals, all processed through an array of analog delays
          and effects that pay homage to the UK dub tradition.
        </p>
        <p>
          At the core of Manduba's sound is their commitment to live
          performance. Each member brings years of experience from Barcelona's
          reggae and dub scene, resulting in an organic live show where
          electronic music meets real-time instrumentation. Their setup allows
          them to recreate the spontaneity and energy of classic dub music while
          incorporating modern production techniques. The band's sonic palette
          draws inspiration from UK steppas pioneers, electronic music
          innovators, and the DIY spirit of punk rock, creating a fresh take on
          dub that connects with diverse audiences. Whether in intimate venues
          or on festival stages, Manduba delivers an authentic and energetic
          performance that stays true to dub music's roots while pushing into
          new territories.
        </p>
      </div>
    </div>
  );
}

export default Home;
