import React from 'react';

function Contact() {
  return (
    <div className="contact">
            <h1 className="hero_title">CONTACT US:</h1>
            <h2 className="hero_subtitle">holamanduba@gmail.com</h2>
            
    </div>
  );
}

export default Contact;