import React from "react";

function Images() {
  return (
    <div className="image-grid">
      <img src="manduba4.jpeg" class="grid-image" alt="Manduba pic 4" />
      <img src="manduba1.jpeg" class="grid-image" alt="Manduba pic" />
      <img src="manduba3.jpeg" class="grid-image" alt="Manduba pic 3" />
      <img src="manduba2.jpeg" class="grid-image" alt="Manduba pic 2" />
    </div>
  );
}

export default Images;
