import React from 'react';

function Music() {
  return (
    <div className="music">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/DtlOHKuT0ug?si=JCphqX1NkP8TdtC8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  <iframe width="560" height="315" src="https://www.youtube.com/embed/AbLkFBquR9Q?si=DhdWdAh0MNC6rw7y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>    </div>
  );
}

export default Music;